<template>
  <div class="index-body">
    <div class="banner">
      <span>期刊</span>
    </div>

    <div class="brief-introduction" @click="closeOpen" :style="{ display: active ? 'flex' : '' }">
      <section class="info">
        <figure>
          <img src="../../assets/recommend/managineInfo.png" alt="" />
        </figure>
        <ul>
          <!-- <li>创刊时间：2020年3月</li> -->
          <li>ISSN：1007-4295</li>
          <li>主管：中共中央党史和文献研究院</li>
          <li>主办：中国中共党史学会</li>
          <li>承办：中共党史出版社</li>
        </ul>
      </section>
      <section class="title">
        <div class="infomsg">
           《百年潮》杂志是由中共中央党史和文献研究院主管、中国中共党史学会主办、中共党史出版社承办的大型时政文史月刊，创刊于1997年。《百年潮》杂志以“揭示历史真相，展现世纪风云”为宗旨，以“信史、实学、新知、美文”为风格，记述百年以来尤其是中国共产党领导各族人民争取民族独立、人民解放和国家富强、人民幸福的历史进程中的重大历史事件、重要历史人物。刊物的主要栏目有：本刊特稿、人物春秋、往事回首、史事忆念、史海钩沉、史事本末、口述历史、名人一瞥等.
        </div>
      </section>
    </div>
    <div class="mian-body" :style="{ display: show ? 'block' : '' }">
      <div class="sidebar">
        <el-menu class="el-menu-vertical-demo" :default-active="dataForm.year">
          <el-menu-item
            :index="item.year"
            v-for="item in yearsList"
            @click="changeYear(item.year)"
            :key="item.id"
          >
            <span>{{ item.year }}</span>
          </el-menu-item>
        </el-menu>
      </div>

      <div class="container">
        <section
          class="button"
          :style="{
            'box-shadow': active
              ? ' 0rem 0.02rem 0.06rem 0rem rgba(0,0,0,0.1)'
              : '',
          }"
        >
          <div class="select" @click="showSelect">
            <section class="icon">
              <svg
                t="1685339716433"
                class="iconselect"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="6967"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                :width="'.24rem'"
                :height="'.24rem'"
              >
                <path
                  d="M855.342766 151.46262c-6.056949-11.851932-18.984377-19.393699-33.219589-19.393699L101.903901 132.068921c-14.360056 0-27.412326 7.704472-33.396621 19.744693-5.988388 12.015661-3.845585 26.157753 5.520737 36.192294l255.896134 274.308483 0 309.339324c0 12.847609 7.895831 24.602328 20.389376 30.328749l189.116195 86.432535c5.154393 2.371 10.771321 3.515057 16.33913 3.515057 6.541997 0 13.090133-1.607614 18.926048-4.797259 10.718109-5.945409 17.427928-16.503882 17.809621-28.037567l12.957103-396.767536 245.078765-274.90507C859.543438 177.316451 861.425298 163.313529 855.342766 151.46262zM520.773827 804.275693l-117.384477-53.647851L403.38935 483.628836l127.858016 0L520.773827 804.275693zM550.774095 416.986019c-1.963725-0.299829-3.761674-1.090844-5.809309-1.090844L383.519814 415.895175 181.938726 199.803605l562.427506 0L550.774095 416.986019zM685.454494 524.008498l273.392624 0 0 59.759035-273.392624 0 0-59.759035ZM685.454494 654.104485l273.392624 0 0 59.759035-273.392624 0 0-59.759035ZM685.454494 773.618463l273.392624 0 0 59.759035-273.392624 0 0-59.759035Z"
                  p-id="6968"
                ></path>
              </svg>
            </section>
            <span>{{ dataForm.year }}</span>
          </div>

          <div class="time" @click="showmsg">
            <figure>
              <img src="../../assets/home/magazineLogo.png" alt="" />
            </figure>
            <i class="el-icon-arrow-down el-icon--right" v-if="!active"></i>

            <i class="el-icon-arrow-up el-icon--right" v-if="active"></i>
          </div>
        </section>

        <ul class="books">
          <li
            v-for="item in dataList"
            class="book"
            :key="item.id"
            @click="goDetail(item.id)"
          >
            <figure>
              <img :src="item.coverUrl" alt="" />
            </figure>
            <div class="books-msg">
              <span>{{
                item.magazineNumberType == 1
                  ? item.magazineTotalNumber
                  : item.year + "-" + (item.magazineNumber >= 10 ? item.magazineNumber:'0'+item.magazineNumber)
              }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="pickerOut" v-if="iPhoneShow">
      <van-picker
        class="picker"
        show-toolbar
        title="选择年份"
        :columns="columns"
        @cancel="onCancel"
        @confirm="onConfirm"
      />
    </div>
  </div>
</template>

 
<script>
import Swiper from "swiper";
export default {
  name: "vue-index",

  data() {
    return {
      msg: "",
      columns: [],
      dataList: [],
      total: 0,
      active: false,
      show: false,
      iPhoneShow: false,
      yearsList: [],
      dataForm: {
        year: "2023",
        status: 1,
        limit:999
      },
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      // // 如果需要分页器
      // pagination: '.swiper-pagination',
      // // 如果需要前进后退按钮
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
      // // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
    });
  },
  async created() {
  
    await this.queryAll();
    this.dataForm.year =  this.yearsList[0] && this.yearsList[0].year
      await this.query();
  },
  methods: {
    goDetail(id) {
      this.$router.push({
        path: "/magazine/magazinedetail",
        query: { id: id },
      });
    },
    closeOpen(){
      this.display =false
    },
    showmsg() {
      this.active = !this.active;
      console.log(this.active);
    },

    changeYear(year) {
      this.dataForm.year = year;
      this.query();
    },
    onConfirm(value) {
      this.dataForm.year = value;

      this.query();
      this.iPhoneShow = !this.iPhoneShow;
    },
    onCancel() {
      this.iPhoneShow = !this.iPhoneShow;
    },
    showSelect() {
      this.iPhoneShow = !this.iPhoneShow;
      console.log("qqqqq");
    },
    confirm() {
      this.show = !this.show;
    },
    selectYears(list) {
  
      this.yearsList = [];
      this.columns = [];
      let obj = {};
      this.yearsList = list.reduce(function (item, next) {
        obj[next.year] ? "" : (obj[next.year] = true && item.push(next));
        return item;
      }, []);
      this.yearsList.sort((a, b) => b.year - a.year);
      this.yearsList.forEach((year) => {
        this.columns.push(year.year);
      });
     
    },
    async queryAll() {
      console.log("resdata");

      const { data: res } = await this.$http.get("/magazine/homePage", {
        params: {
          status: 1,
          limit:10000
        },
      });

      if (res.code !== 0) {
        this.dataList = [];
        this.total = 0;
        return this.$message.error(res.msg);
      }
      this.selectYears(res.data.list);
    },
    async query() {
      console.log("resdata");

      const { data: res } = await this.$http.get("/magazine/homePage", {
        params: {
          ...this.dataForm,
        },
      });

      if (res.code !== 0) {
        this.dataList = [];
        this.total = 0;
        return this.$message.error(res.msg);
      }
      console.log(this.dataList,'this.dataList');
      this.dataList = res.data.list;
    },
  },
  watch: {
       active(val) {
      if (val) {
        document.body.className = "body-scrollCtrl";
      } else {
        document.body.removeAttribute("class", "body-scrollCtrl");
      }},
        iPhoneShow(val) {
      if (val) {
        document.body.className = "body-scrollCtrl";
      } else {
        document.body.removeAttribute("class", "body-scrollCtrl");
      }},

   }
};
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 0.56rem;
  height: 3.3rem;
  background: linear-gradient(180deg, #b20103 0%, #f8f8f8 100%);

  span {
    position: relative;
    left: 0.84rem;
    top: 0.64rem;
    font-size: 0.48rem;
    font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 0.56rem;
  }
}
.mian-body {
  position: relative;
  top: -1.2rem;
  // height: 8.65rem;
  padding: 0 0.64rem;
  margin-bottom: -0.88rem;
  .topone {
    padding-left: 0.32rem !important;
    height: 0.56rem;
    margin-bottom: 0;
  }
  .topone:hover {
    background: #ffffff;
  }
  .topone.is-active {
    background: #ffffff;
  }
  .topone.is-active::after {
    display: none;
  }
}
.mian-body {
  display: flex;
  justify-content: space-between;
}
.sidebar {
  width: 2.4rem;
  // height: 8.65rem;
  background: #ffffff;
 
}
.container {
  position: relative;
  box-sizing: border-box;
  width: 10.56rem;
  min-height: 8.65rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  padding: 0.62rem 0.32rem;
  .books {
    display: flex;
    flex-wrap: wrap;
    //   justify-content: space-between;
    cursor: pointer;

    li {
      margin-right: 0.33rem;
      margin-bottom: 0.31rem;
      display: flex;
      flex-direction: column;
    }
    li:nth-child(4n) {
      margin-right: 0;
    }
    figure {
      width: 2.2rem;
      height: 2.2rem;
      background: #ffffff;

      border: 0.01rem solid #eeeeee;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 90%;
      }
    }
    .books-msg {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      line-height: 0.24rem;

      font-size: 0.16rem;
      text-align: center;
      margin-top: 0.15rem;
    }
  }
}

/deep/ .el-icon-arrow-down:before {
  color: var(--backgroundColor);
  transition: 0.3s all;
}
/deep/ .el-icon-arrow-up::before {
  color: var(--backgroundColor);
  transition: 0.3s all;
}
/deep/ .el-menu-item {
  font-weight: 500;
  font-size: .14rem;
  color: rgba(0, 0, 0, 0.9);
  height: 0.48rem;
  line-height: 0.48rem;
  padding-left: 0.64rem !important;
  position: relative;
  margin-bottom: 0.08rem;
 
}
/deep/ .el-submenu__title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.56rem;
  line-height: 0.48rem;
  padding-left: 0.32rem !important;
}
/deep/.el-menu-item:hover {
  background: #eeeeee;
}
/deep/.el-submenu__title:hover {
  background: #ffffff;
}
/deep/ .el-menu-item.is-active {
  background: rgba(195, 40, 43, 0.1);
}

.el-menu-item.is-active::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "";
  width: 0.04rem;
  height: 0.48rem;
  background: #c3282b;
}

.pagination {
  position: absolute;
  right: 0.32rem;
  margin-top: 0.47rem;
  display: flex;
  align-items: center;
  span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
    margin-right: 0.16rem;
  }
  .go {
    margin-right: 0.08rem;
  }
  .page {
    margin-left: 0.14rem;
  }
  /deep/ .el-input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-input__inner {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }

  /deep/.el-button {
    width: 0.6rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    // line-height: .02rem;

    padding: 0;
  }
  /deep/.el-button span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
}

.brief-introduction {
  position: relative;
  top: -1.22rem;
  margin: 0 0.64rem;
  width: 13.1rem;
  height: 2.22rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  margin-bottom: 0.16rem;
  display: flex;
  .info {
    padding: 0.3rem 0.32rem;
    border-right: 2px dashed rgba(238, 238, 238, 1);
    padding-right:0 ;
    flex-shrink: 0;
    // width: 8rem;
    display: flex;
    figure {
      width: 2.4rem;
      height: 1.58rem;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
    ul {
      display: flex;
      // flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-around;
      margin:0 0.5rem;
      // align-items: center;
      // margin-top: 0.3rem;
      li {
        // width: 1.7rem;
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        // line-height: 0.22rem;
        &:nth-child(2) {
          // width: 2.5rem;
        }
      }
    }
  }
  .title {
    // width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

    .infomsg {
      // margin: 0.62rem 1.08rem 0.64rem 0.8rem;
    
      font-size: 0.14rem;
      margin: .3rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.25rem;
    }
  }
}
.button {
  display: none;
}
@media (max-width: 768px) {
  .index-body {
    margin-top: 0;
    padding-bottom: 0.44rem;
    padding-left: 0;
    background: #ffffff;

    .mian-body {
      height: auto;
      top: 0;
      padding: 0;
      margin-bottom: 0;
      .container {
        padding: 0;
        width: 3.75rem;
        height: auto;
        .button {
          width: 3.75rem;
          height: 0.56rem;
          background: #ffffff;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          z-index: 9;
          position: relative;
          div {
            background: #ffffff;
            // width: 0.96rem;
            justify-content: flex-end;
            span {
              font-size: 0.14rem;
              color: rgba(0, 0, 0, 0.9);
            }
          }
          .select {
            display: flex;
            flex-grow: 1;
            justify-content: flex-start;
            margin-left: 0.16rem;
            align-items: center;
            span {
              margin-left: 0.08rem;
            }
            svg {
              fill: var(--backgroundColor);
            }
          }
          .time {
            margin-right: 0.22rem;
            display: flex;
            align-items: center;
            figure {
              width: 0.78rem;
              height: 0.28rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          svg {
            width: 100 !important;
            height: 100 !important;
          }
        }
        .books {
          width: 100%;
          box-sizing: border-box;
          justify-content: space-between;
          padding: 0 0.21rem;
          height: auto;
          li {
            margin: 0;
            // margin-bottom: 0.14rem;
            // margin-left: 0.15rem;

            figure {
              width: 1.56rem;
              height: 1.56rem;
            }
            .books-msg {
              margin-top: 0.14rem;
              margin-bottom: 0.22rem;
              span:nth-child(1) {
                margin: 0;
                font-size: 0.16rem;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
                line-height: 0.24rem;
                margin-bottom: 0.1rem;
              }
              span:nth-child(2) {
                margin: 0;
                i {
                  display: inline-block;
                  padding: 0 0.08rem;
                  height: 0.24rem;
                  line-height: 0.24rem;

                  background: rgba(255, 244, 244, 1);
                  border-radius: 0.02rem;
                  color: rgba(195, 40, 43, 1);
                }
                i:nth-child(1) {
                  // margin-right: .02rem;
                  background: #f3e3e3;
                }
              }
              span:nth-child(3) {
                display: none;
              }
              span:nth-child(4) {
                margin-top: 0.12rem;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
                line-height: 0.22rem;
                width: 2.37rem;

                height: 0.4rem;
                font-size: 0.13rem;
                display: -webkit-box;
                overflow: hidden;
                white-space: normal !important;
                text-overflow: ellipsis;
                word-wrap: break-word;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
      .sidebar {
        display: none;
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;

        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        .sidebarInner {
          width: 3rem;
          height: 100vh;
          background: #ffffff;
          border-radius: 0rem 0.16rem 0.16rem 0rem;
          overflow: hidden;
        }
        .sidebarTop {
          margin-top: 0.6rem;
          margin-bottom: 0.07rem;
          margin-left: 0.3rem;
          display: flex;
          span {
            
            height: 0.24rem;
            font-size: 0.16rem;

            font-weight: 500;
            color: rgba(0, 0, 0, 0.9);
            line-height: 0.24rem;
            margin-left: 0.62rem;
          }
        }

        .buttons {
          margin: 0 0.56rem;
          margin-top: 0.32rem;
          display: flex;
          justify-content: space-between;

          .reset {
            width: 0.86rem;
            height: 0.32rem;
            background: #fff4f4;
            border-radius: 0.04rem;

            font-size: 0.14rem;

            font-weight: 400;
            color: #c3282b;
            line-height: 0.32rem;
            text-align: center;
          }
          .confirm {
            width: 0.86rem;
            height: 0.32rem;
            background: #c3282b;
            border-radius: 0.04rem;
            text-align: center;
            font-size: 0.14rem;

            font-weight: 400;
            color: #ffffff;
            line-height: 0.32rem;
          }
        }
      }
    }
    .brief-introduction {
      display: none;
      position: absolute;
      height: 3.13rem;
      width: auto;
      top: 1.1rem;
      left: 0;
      z-index: 9;
      margin: 0;
      flex-direction: column-reverse;
      height: auto;

      figure {
        display: none;
      }
      .infoTitle {
        display: none;
      }
      .infomsg {
        margin: 0rem 0.16rem;
        border-bottom: 0.01rem solid #eeeeee;
        padding: 0.24rem 0;
      }
      .title {
        z-index: 9;
        margin: 0;
        padding: 0;
        width: auto;
        // margin: .24rem 0;
        font-size: 0.14rem;
        background: #ffffff;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 0.22rem;
      }
      .info {
        z-index: 9;
        margin: 0;
        padding: 0;
        width: auto;
        background: #ffffff;
        ul {
          margin-top: 0.24rem;
          margin-bottom: 0.24rem;
          margin-left: .24rem;
          li {
            font-size: 0.14rem;

            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 0.22rem;
            margin-bottom: 0.08rem;
          }
        }
      }
    }
    .brief-introduction::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;

      background: rgba(0, 0, 0, 0.65);
      width: 100vw;
      height: 100vh;
    }

    .banner {
      display: none;
    }
  }
  /deep/ .el-icon-arrow-left::before {
    content: "\e6de";
    color: var(--backgroundColor);
    font-size: 0.2rem;
    font-weight: 600;
  }
  .pickerOut {
    position: fixed;
    top: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
  }
  .picker {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
    /deep/ .van-picker__toolbar {
      border-bottom: 1px solid #eeeeee;
      height: 0.56rem;
      font-size: 0.16rem;

      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.24rem;
    }
    /deep/.van-picker__title {
      font-size: 0.16rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      line-height: 0.24rem;
    }
    /deep/.van-picker-column__item--selected {
      font-size: 0.2rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.28rem;
    }
    /deep/ .van-picker__confirm {
      color: var(--backgroundColor);
    }
  }
}
</style>